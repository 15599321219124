import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Home.css";
import IntroAnimation from "./IntroAnimation.js";
import aboutImg from "./../assets/home/abCover.webp";
import door from "./../assets/home/doors.webp";
import window from "./../assets/home/windows.webp";
import curtainWall from "./../assets/home/curtain.webp";
// import alumil from "./../assets/home/alumil.webp";
// import elvial from "./../assets/home/elvial.webp";
import Testimonials from "./Testimonials.js";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMarker, faCommentsDollar, faPersonThroughWindow } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// Import images for each project
import madrona1 from "./../assets/gallery/madrona1.jpg";
import morgan1 from "./../assets/gallery/morgan1.jpg";
import narrows1 from "./../assets/gallery/narrows1.jpg";
import lionsbayext1 from "./../assets/gallery/lionsbayext1.jpg";
import craft1 from "./../assets/gallery/craft1.jpg";
import eHastings1 from "./../assets/gallery/eHastings1.jpg";

const Home = () => {
  return (
    <div>
      <main>
        <IntroAnimation /> {/* Use the IntroAnimation component */}
        <div className="hero bg-cover bg-center flex items-center pt-10 md:pt-10 flex-col h-screen">
          <h1 className="text-[#36454F] text-left font-suranna text-4xl md:text-6xl md:pb-2 font-normal leading-8 tracking-widest uppercase">
            VARSA
          </h1>
          <p className="text-[#36454F] text-left font-suranna pt-2 md:pt-4 text-lg md:text-lg font-light leading-8 tracking-wider uppercase">
            Windows & Doors
          </p>
        </div>

        <div className="flex flex-col justify-center md:pt-32 bg-white">
          <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto">
            <div className="bg-[#F1F1F1] w-full md:w-1/4 md:pb-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
              <div className="font-montserrat font-black flex justify-between item-center underline text-sm pb-2 pt-6 md:pb-7">
                OUR WORK
              </div>
              <h3 className="font-montserrat font-semibold text-black text-left pb-3 text-lg md:text-xl tracking-wide">
                PROJECTS
              </h3>
              <p className="text-left leading-8 font-montserrat font-medium tracking-narrow pb-5">
                Come explore all of our recent work ranging from custom doors, windows, curtain walls, passive housing, and more.
              </p>
              <Link className="text-left" to="projects">
                <button className="bg-black hover:bg-gray-700 text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500">
                  Explore
                </button>
              </Link>
            </div>
            <div className="md:w-3/4 grid place-items-center">
              <Carousel className="projects-carousel" autoPlay infiniteLoop showThumbs={false}>
                <div>
                  <img className="carousel-image" src={madrona1} alt="Madrona Project 1" />
                </div>
                <div>
                  <img className="carousel-image" src={lionsbayext1} alt="Lions Bay Project 1" />
                </div>
                <div>
                  <img className="carousel-image" src={morgan1} alt="Morgan Project 1" />
                </div>
                <div>
                  <img className="carousel-image" src={narrows1} alt="Narrows Project 1" />
                </div>
                <div>
                  <img className="carousel-image" src={craft1} alt="Craft Project 1" />
                </div>
                <div>
                  <img className="carousel-image" src={eHastings1} alt="East Hastings Project 1" />
                </div>
                {/* Add more project images as needed */}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center pt-24 md:pt-32 bg-white">
          <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto">
            <div className="md:w-2/4 grid place-items-center">
              <img
                src={aboutImg}
                alt="About Us - Patio door"
                className="object-cover w-full h-full"
                loading="lazy"
              />
            </div>

            <div className="bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
              <div className="font-montserrat font-black flex justify-between item-center underline text-sm pb-2 md:pb-7">
                LEARN
              </div>
              <h3 className="font-montserrat font-semibold text-black text-left md:pb-3 text-lg md:text-xl tracking-wide">
                ABOUT US
              </h3>
              <p className="text-left leading-8 font-montserrat font-medium tracking-narrow pb-5">
                We are Varsa Windows and Doors and have been doing business since 6. Originally, manufacturing and wholesaling of windows to window companies and as of 2012, selling and installing. We are a local family business that believes in excellent customer service along with high-end quality products.
              </p>
              <Link className="text-left" to="about">
                <button className="bg-black hover:bg-gray-700 text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center pt-24 md:pt-32 bg-white">
          <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-black leading-6 underline-offset-8">
            COLLECTIONS
          </h3>
          <p className="font-montserrat font-medium text-center md:space-x-5 space-y-3 md:space-y-0 max-w-full md:max-w-5xl mx-auto pr-10 pl-10 pt-6 pb-6 text-md">
            Rooted in Greece's distinguished reputation for aluminum production, Varsa Windows and Doors proudly upholds the highest standards of quality and innovation in our craft.
          </p>
        </div>
        <div className="flex flex-col justify-center pt-2 ">
          <div className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-xl md:max-w-6xl mx-auto bg-white">
            <div className="w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105">
              <Link to="doors">
                <img
                  src={door}
                  alt="Pivot Door"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold text-left text-sm tracking-wide pt-2">
                DOORS
              </span>
            </div>
            <div className="w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105">
              <Link to="windows">
                <img
                  src={window}
                  alt="European Window"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold text-left pb-3 text-sm tracking-wide pt-2">
                WINDOWS
              </span>
            </div>
            <div className="w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105">
              <Link to="curtain">
                <img
                  src={curtainWall}
                  alt="Curtain Wall"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold text-left pb-3 text-sm tracking-wide pt-2">
                CURTAIN WALLS
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center pt-24 md:pt-32 bg-white">
          <h3 className="pb-10 font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-black leading-6 underline-offset-8">
            TESTIMONIALS
          </h3>

          <Testimonials />
        </div>

        <section className="bg-[#F1F1F1] mt-12">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
              <h2 className="font-montserrat font-semibold mb-4 md:text-4xl text-lg tracking-tight font-extrabold leading-tight">
                Contact us to find out more
              </h2>
              <p className="font-montserrat font-medium mb-6 font-light md:text-lg">
                Upgrade your living spaces with Varsa Windows and Doors today! Discover the perfect blend of luxury, security, and Greek-inspired craftsmanship. Get a quote now!
              </p>
              <Link className="text-left" to="contact">
                <button className="bg-black hover:bg-gray-700 text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500">
                  GET IN TOUCH
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;