import React from "react";
import aboutCover2 from "./../assets/about/abcover.webp";
import commitment from "./../assets/about/commitment.webp";
import teamMember1 from "./../assets/team/george.jpg";
import teamMember2 from "./../assets/team/jag.jpg";
import teamMember3 from "./../assets/team/henry.jpg";
import teamMember4 from "./../assets/team/raj.jpg";
import teamMember5 from "./../assets/team/cristina.jpg";
import teamMember6 from "./../assets/team/sandra.jpg";
import teamMember7 from "./../assets/team/taha.jpg";
// import teamMember8 from "./../assets/team/jenny.jpg";
import teamMember9 from "./../assets/team/kevin.jpg";

import { Link } from "react-router-dom";

const teamMembers = [
  { name: "George Varnas", title: "CEO", img: teamMember1 },
  { name: "Raj Grewal", title: "Lead Engineer", img: teamMember4 },
  { name: "Sandra Guerrero", title: "Glazing Assistant", img: teamMember6 },
  { name: "Taha Shaikh", title: "Project Manager", img: teamMember7 },
  { name: "Cristina Dietrich", title: "Operations Manager", img: teamMember5 },
  { name: "Henry Randhawa", title: "Site Supervisor", img: teamMember3 },
  { name: "Jag Cheema", title: "Senior Sales and Marketing", img: teamMember2 },
  { name: "Kevin Stonehouse", title: "Sales Executive", img: teamMember9 },
  // { name: "Jenny Doe", title: "Marketing Specialist", img: teamMember8 },
];

const About = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        ABOUT US
      </h1>

      {/* Section 1 */}

      <div className="flex flex-col justify-center pt-12  bg-white">
        <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto">
          <div className="w-full md:w-2/4 grid place-items-center">
            <img
              src={aboutCover2}
              alt="Varsa cover"
              className="object-cover w-full"
            />
          </div>
          <div className="bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              WE ARE A WINDOWS & DOORS MANUFACTURER
            </h3>
            <p className="text-left leading-8 md:text-base font-montserrat font-medium tracking-narrow">
              At Varsa Windows and Doors, we are more than just a business – we
              are a testament to our Greek heritage and a celebration of our
              roots. Since our establishment in 2006, we have been dedicated to
              crafting and delivering exceptional windows and doors. <br /><br />What began
              as a manufacturing and wholesale operation has grown into a
              comprehensive service, encompassing sales and professional
              installation since 2012. We take immense pride in providing
              outstanding customer service while delivering high-end quality
              products. <br /><br />Our Greek heritage inspires us to infuse our craftsmanship
              with elegance, durability, and a touch of Mediterranean charm.
              Join us on this journey as we combine tradition with innovation to
              transform your spaces.
            </p>
          </div>
        </div>
      </div>

      {/* Section 2 */}

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pt-24 md:pt-32">
        OUR TEAM
      </h2>

      <div className="flex flex-wrap justify-center bg-white pt-24 md:pt-32">
        <div className="max-w-sm md:max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <img
                src={member.img}
                alt={`${member.name}'s headshot`}
                className="w-40 h-40 object-cover rounded-full"
              />
              <h3 className="font-montserrat font-semibold text-lg mt-4">
                {member.name}
              </h3>
              <p className="font-montserrat text-sm text-gray-600">
                {member.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Section 3 */}

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pt-24 md:pt-32">
        OUR PROCESS
      </h2>

      <div className="flex flex-wrap justify-center bg-white pt-24 md:pt-32">
        <div className="max-w-sm md:max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center text-center p-6 bg-[#F1F1F1] ">
            <span className="font-montserrat font-bold text-center pt-6 text-lg tracking-wide">
              DESIGN
            </span>
            <p className="text-left leading-8 font-montserrat font-medium text-md tracking-narrow pt-5">
              We first meet with you to gather a complete understanding of your
              vision, taking into account the site location, features, and
              projected budget.
            </p>
          </div>

          <div className="flex flex-col items-center text-center p-6 bg-[#F1F1F1] ">
            <span className="font-montserrat font-bold text-center pt-6 text-lg tracking-wide">
              BUILD
            </span>
            <p className="text-left leading-8 font-montserrat font-medium text-md tracking-narrow pt-5">
              At Varsa we turn your approved plans into a reality by carefully
              managing every detail. Our hands-on approach ensures execution of
              your vision.
            </p>
          </div>

          <div className="flex flex-col items-center text-center p-6 bg-[#F1F1F1] ">
            <span className="font-montserrat font-bold text-center pt-6 text-lg tracking-wide">
              INSTALL
            </span>
            <p className="text-left leading-8 font-montserrat font-medium text-md tracking-narrow pt-5">
              Whether you need materials supplied or a full install, we’re here
              to help. Our transparent, precise process ensures a stunning
              outcome while keeping your vision front and center.
            </p>
          </div>
        </div>
      </div>
      
      {/* Section 4 */}

      <div className="flex flex-col justify-center pt-24 md:pt-32 pb-12 md:pb-32 bg-white">
        <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto">
          <div className="md:w-2/4 grid place-items-center">
            <img
              src={commitment}
              alt="commitment"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 text-xl tracking-wide">
              OUR COMMITMENT
            </h3>
            <p className="text-left leading-8 font-montserrat font-medium tracking-narrow pb-5">
              We emphasize design, quality, and craftsmanship in all of our
              work. We pride ourselves in creating elegantly beautiful, unique
              and functional Glass Installations to make your dream a reality.
              Contact us today to discuss your Custom Glazing needs.
            </p>
            <Link className="text-left" to="../contact">
              <button className="bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500">
                GET IN TOUCH
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;