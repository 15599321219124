import React from "react";
import s77Cover from "../../../assets/s77/s77Cover.webp";
import s77a from "../../../assets/s77/s77a.webp";
import s77b from "../../../assets/s77/s77b.webp";
import s771 from "../../../assets/s77/s771.webp";
import s772 from "../../../assets/s77/s772.webp";
import s773 from "../../../assets/s77/s773.webp";
import s774 from "../../../assets/s77/s774.webp";
import s775 from "../../../assets/s77/s775.webp";
import s776 from "../../../assets/s77/s776.webp";
import s777 from "../../../assets/s77/s777.webp";
import s778 from "../../../assets/s77/s778.webp";
import s779 from "../../../assets/s77/s779.webp";
import s77Hero from "../../../assets/alumil/S77Section.jpg";

import s77sec1 from "../../../assets/s77/s77sec1.webp";
import s77sec2 from "../../../assets/s77/s77sec2.webp";
import s77sec3 from "../../../assets/s77/s77sec3.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S77 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL S77
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Hinged insulated system SMARTIA S77
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={s77Hero}
                alt="S77 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The system SMARTIA S77 is ALUMIL's proposal for advanced
                insulating casements, which meet the high standards of all
                advanced markets and incorporate a modern design. It is the
                ideal solution for projects that require high performance and
                maximum security.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold text-xl tracking-narrow md:pb-2 ">
                Specifications
              </p>
              <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-3 max-w-7xl md:text-base text-left pt-6  ">
                <span className="pr-10 font-semibold ">
                  WINDOW TYPE
                  <span className="pr-10 block font-normal pb-4">Hinged</span>
                </span>
                
                <span className="pr-10 font-semibold ">
                  FRAME DEPTH
                  <span className="pr-10 block font-normal pb-4">77 mm</span>
                </span>

                <span className="pr-10 font-semibold ">
                  U-VALUE
                  <span className="pr-10 block font-normal pb-4">
                    from 0.85 to 2.42 W/m²K
                  </span>
                </span>

               

                <span className="pr-10 font-semibold ">
                  BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">RC2/RC3</span>
                </span>

                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">
                    CLASS E1350
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                </span>
                
                <span className="pr-10 font-semibold ">
                  MAXIMUM GLAZING THICKNESS
                  <span className="pr-10 block font-normal pb-4">
                   up to 72 mm
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  MINIMUM VISIBLE ALUMINIUM FACE HEIGHT
                  <span className="pr-10 block font-normal pb-4">93 mm</span>
                </span>
              </div>
            </div>

            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 h-full ">
            <img
              src={s77Hero}
              alt="S77 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">

      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={s77a} alt="S77 Render" />
              </div>
              <div class="w-full ">
                <img src={s77b} alt="S77 Render" />
              </div>
            </div>
          </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={s771}
                  alt="S77 Render"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={s772}
                  alt="S77 Render"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents plus door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s773}
                  alt="S77 Render "
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={s774}
                  alt="S77 Render"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s775}
                  alt="S77 Render"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with single door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s776}
                  alt="S77 Render"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with odd number of vents on both sides
              </span>

              <span className="pr-10 pb-4 ">
                <img
                  src={s777}
                  alt="S77 Render"
                  className="object-contain h-24 mx-auto "
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={s778}
                  alt="S77 Render"
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={s779}
                  alt="S77 Render "
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents plus door
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
         
        </div>
      </div>
      <div className="  flex flex-col justify-left  pb-2">
        <div className="  md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s77sec1}
              alt="S77 Sectional"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s77sec2}
              alt="S77 Sectional"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={s77sec3}
              alt="S77 Sectional"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/smartia-brochure-gr-en.pdf?sfvrsn=2dc3db89_78"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            Smartia Aluminum Systems
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/pre-qualification-sd77f4e3a81aba954c1b8c233f00e0788cd4.pdf?sfvrsn=2ebdd2d8_31"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            S77 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/smartia-s77-en.pdf?sfvrsn=9f4a77ee_59"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            S77 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default S77;