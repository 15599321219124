import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import koemmerlingCover from "../../../assets/koemmerling/koemmerlingCover.webp";
import centerSeal from "../../../assets/koemmerling/centerSeal.webp";

const Koemmerling = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-7xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              Koemmerling
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Window & residential door systems
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={koemmerlingCover}
                alt="Kommerling"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                As an end to end provider, Kömmerling presents an extensive
                range of window and shutter systems that is a clear cut above
                the competition. Our widely diversified range of systems also
                provides the basis for creative solutions tailored specifically
                to quite individual needs. In addition, the Kömmerling brand is
                a leading manufacturer of PVC-U sheets for the most diverse
                applications. This line of quality products marketed worldwide
                forms a strong unit in the profine Group. The range is rounded
                off with customised profile systems for the semi-finished
                products sector.
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={koemmerlingCover}
              alt="Kommerling"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        SERIES
      </h2>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
        
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
            <Link to="center76">
              <img
                src={centerSeal}
                alt="76mm center seal cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              76 Center Seal
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Koemmerling;
