import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import cover from "../../assets/s67Urban/S67Cover.jpg";
import elvialCover from "../../assets/windows/elvialCover.jpg";
import alumilCover from "../../assets/windows/alumilCover.jpg";
import rehau from "../../assets/windows/window5Passive.webp";
import koemmerling from "../../assets/windows/window6.webp";

const Windows = () => {
  return (
    <div>
      {" "}
      <h1 className="door-cover text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        WINDOWS
      </h1>
      <div className=" flex flex-col justify-center pb-12 md:pt-12  bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={cover}
              alt="cover"
              className="object-cover h-full w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR WINDOWS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              At Varsa Windows and Doors, we offer a wide selection of windows
              to suit your needs. Our Aluminum and PVC frame options provide
              outstanding choices, with our Aluminum frames representing the
              highest level of quality. Built with precision and durability in
              mind, our Aluminum windows exude elegance and strength. Our PVC frame windows deliver excellent
              insulation and low maintenance. Whichever you choose, our windows
              are crafted to elevate your living spaces with style and
              precision. Experience the finest in window craftsmanship with
              Varsa Windows and Doors.
            </p>
          </div>
        </div>
      </div>
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        ALUMINUM
      </h2>
      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="alumil">
              <img
                src={alumilCover}
                alt="Doors cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL PERFORMANCE SERIES{" "}
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="elvial/w77">
              <img
                src={elvialCover}
                alt="Windows Cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ELVIAL ULTRA SLIM SERIES
            </span>
          </div>
         
        </div>
      </div>
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        PVC
      </h2>
      <div className="  flex flex-col justify-center pt-2 pb-2 md:pb-12">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="rehau">
              <img
                src={rehau}
                alt="Doors cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              REHAU GENEO (UPVC) SERIES{" "}
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="koemmerling">
              <img
                src={koemmerling}
                alt="Windows Cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              KOEMMERLING SERIES{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Windows;
