import React, { useState } from "react";
import Modal from "react-modal";

// Import images for each project
import madrona1 from "./../assets/gallery/madrona1.jpg";
import madrona2 from "./../assets/gallery/madrona2.jpg";
import madrona3 from "./../assets/gallery/madrona3.jpg";
import madrona4 from "./../assets/gallery/madrona4.jpg";
import madrona5 from "./../assets/gallery/madrona5.jpg";
import madrona6 from "./../assets/gallery/madrona6.jpg";
import madrona7 from "./../assets/gallery/madrona7.jpg";
import madrona8 from "./../assets/gallery/madrona8.jpg";
import morgan1 from "./../assets/gallery/morgan1.jpg";
import morgan2 from "./../assets/gallery/morgan2.jpg";
import morgan3 from "./../assets/gallery/morgan3.jpg";
import morgan6 from "./../assets/gallery/morgan6.jpg";
import morgan7 from "./../assets/gallery/morgan7.jpg";
import morgan9 from "./../assets/gallery/morgan9.jpg";
import morgan14 from "./../assets/gallery/morgan14.jpg";
import morgan15 from "./../assets/gallery/morgan15.jpg";
import narrows1 from "./../assets/gallery/narrows1.jpg";
import narrows2 from "./../assets/gallery/narrows2.jpg";
import narrows3 from "./../assets/gallery/narrows3.jpg";
import narrows4 from "./../assets/gallery/narrows4.jpg";
import narrows5 from "./../assets/gallery/narrows5.jpg";
import narrows6 from "./../assets/gallery/narrows6.jpg";
import lionsbayext1 from "./../assets/gallery/lionsbayext1.jpg";
import lionsbayext3 from "./../assets/gallery/lionsbayext3.jpg";
import lionsbay1 from "./../assets/gallery/lionsbay1.jpg";
import lionsbay2 from "./../assets/gallery/lionsbay2.jpg";
import lionsbay3 from "./../assets/gallery/lionsbay3.jpg";
import lionsbay4 from "./../assets/gallery/lionsbay4.jpg";
import lionsbay5 from "./../assets/gallery/lionsbay5.jpg";
import lionsbay6 from "./../assets/gallery/lionsbay6.jpg";
import lionsbay7 from "./../assets/gallery/lionsbay7.jpg";
import lionsbay8 from "./../assets/gallery/lionsbay8.jpg";
import craft1 from "./../assets/gallery/craft1.jpg";
import craft2 from "./../assets/gallery/craft2.jpg";
import craft3 from "./../assets/gallery/craft3.jpg";
import craft4 from "./../assets/gallery/craft4.jpg";
import craft5 from "./../assets/gallery/craft5.jpg";
import craft6 from "./../assets/gallery/craft6.jpg";
import craft7 from "./../assets/gallery/craft7.jpg";
import craft9 from "./../assets/gallery/craft9.jpg";
import craft10 from "./../assets/gallery/craft10.jpg";
import craft11 from "./../assets/gallery/craft11.jpg";
import eHastings1 from "./../assets/gallery/eHastings1.jpg";
import eHastings2 from "./../assets/gallery/eHastings2.jpg";
import eHastings3 from "./../assets/gallery/eHastings3.jpg";
import eHastings4 from "./../assets/gallery/eHastings4.jpg";
import eHastings5 from "./../assets/gallery/eHastings5.jpg";
import eHastings6 from "./../assets/gallery/eHastings6.jpg";
import eHastings7 from "./../assets/gallery/eHastings7.jpg";
import eHastings8 from "./../assets/gallery/eHastings8.jpg";
import eHastings9 from "./../assets/gallery/eHastings9.jpg";
import eHastings10 from "./../assets/gallery/eHastings10.jpg";
import d2 from "./../assets/gallery/25232.jpg";
import d4 from "./../assets/gallery/25234.jpg";
import d5 from "./../assets/gallery/25235.jpg";
import d7 from "./../assets/gallery/25237.jpg";
import d10 from "./../assets/gallery/252310.jpg";
import d11 from "./../assets/gallery/252311.jpg";
import d12 from "./../assets/gallery/252312.jpg";
import d13 from "./../assets/gallery/252313.jpg";
import d14 from "./../assets/gallery/252314.jpg";
import d15 from "./../assets/gallery/252315.jpg";
import e2 from "./../assets/gallery/25302.jpg";
import e3 from "./../assets/gallery/25303.jpg";
import e4 from "./../assets/gallery/25304.jpg";
import e5 from "./../assets/gallery/25305.jpg";
import e7 from "./../assets/gallery/25307.jpg";
import e8 from "./../assets/gallery/25308.jpg";
import e9 from "./../assets/gallery/25309.jpg";
import e10 from "./../assets/gallery/253010.jpg";
import e11 from "./../assets/gallery/253011.jpg";
import e12 from "./../assets/gallery/253012.jpg";
import e13 from "./../assets/gallery/253013.jpg";
import e15 from "./../assets/gallery/253015.jpg";
import e16 from "./../assets/gallery/253016.jpg";
import e17 from "./../assets/gallery/253017.jpg";
import e18 from "./../assets/gallery/253018.jpg";
import f2 from "./../assets/gallery/28552.jpg";
import f7 from "./../assets/gallery/28557.jpg";
import f9 from "./../assets/gallery/28559.jpg";
import f11 from "./../assets/gallery/285511.jpg";
import f14 from "./../assets/gallery/285514.jpg";
import f17 from "./../assets/gallery/285517.jpg";
import f21 from "./../assets/gallery/285521.jpg";
import g2 from "./../assets/gallery/38732.jpg";
import g3 from "./../assets/gallery/38733.jpg";
import g5 from "./../assets/gallery/38735.jpg";
import h1 from "./../assets/gallery/62421.jpg";
import h2 from "./../assets/gallery/62422.jpg";
import h3 from "./../assets/gallery/62423.jpg";
import h4 from "./../assets/gallery/62424.jpg";
import h5 from "./../assets/gallery/62425.jpg";
import h6 from "./../assets/gallery/62426.jpg";
import h7 from "./../assets/gallery/62427.jpg";
import h8 from "./../assets/gallery/62428.jpg";
import h9 from "./../assets/gallery/62429.jpg";
import h10 from "./../assets/gallery/624210.jpg";
import hr from "./../assets/gallery/6242r.jpg";
import i1 from "./../assets/gallery/75171.jpg";
import i2 from "./../assets/gallery/75172.jpg";
import i3 from "./../assets/gallery/75173.jpg";
import i4 from "./../assets/gallery/75174.jpg";
import i5 from "./../assets/gallery/75175.jpg";
import i6 from "./../assets/gallery/75176.jpg";
import i7 from "./../assets/gallery/75177.jpg";
import i8 from "./../assets/gallery/75178.jpg";
import i9 from "./../assets/gallery/75179.jpg";
import i10 from "./../assets/gallery/751710.jpg";
import i11 from "./../assets/gallery/751711.jpg";
import i12 from "./../assets/gallery/751712.jpg";
import i13 from "./../assets/gallery/751713.jpg";
import i14 from "./../assets/gallery/751714.jpg";
import i15 from "./../assets/gallery/751715.jpg";
import i16 from "./../assets/gallery/751716.jpg";
import i17 from "./../assets/gallery/751717.jpg";
import i18 from "./../assets/gallery/751718.jpg";
import j1 from "./../assets/gallery/125091.jpg";
import j2 from "./../assets/gallery/125092.jpg";
import j4 from "./../assets/gallery/125094.jpg";
import j5 from "./../assets/gallery/125095.jpg";
import j6 from "./../assets/gallery/125096.jpg";
import j7 from "./../assets/gallery/125097.jpg";
import j8 from "./../assets/gallery/125098.jpg";
import j9 from "./../assets/gallery/125099.jpg";
import j10 from "./../assets/gallery/1250910.jpg";
import j11 from "./../assets/gallery/1250911.jpg";
import k1 from "./../assets/gallery/125901.jpg";
import k2 from "./../assets/gallery/125902.jpg";
import k3 from "./../assets/gallery/125903.jpg";
import k4 from "./../assets/gallery/125904.jpg";
import k5 from "./../assets/gallery/125905.jpg";
import k7 from "./../assets/gallery/125907.jpg";
import k8 from "./../assets/gallery/125908.jpg";
import k9 from "./../assets/gallery/125909.jpg";
import k10 from "./../assets/gallery/1259010.jpg";
import k11 from "./../assets/gallery/1259011.jpg";
import k12 from "./../assets/gallery/1259012.jpg";
import k13 from "./../assets/gallery/1259013.jpg";
import k14 from "./../assets/gallery/1259014.jpg";
import k15 from "./../assets/gallery/1259015.jpg";
import k16 from "./../assets/gallery/1259016.jpg";
import k18 from "./../assets/gallery/1259018.jpg";
import k19 from "./../assets/gallery/1259019.jpg";
import k23 from "./../assets/gallery/1259023.jpg";
import kr from "./../assets/gallery/12590r.jpg";
import l4 from "./../assets/gallery/133954.jpg";
import l5 from "./../assets/gallery/133955.jpg";
import l7 from "./../assets/gallery/133957.jpg";
import l11 from "./../assets/gallery/1339511.jpg";
import l13 from "./../assets/gallery/1339513.jpg";
import l18 from "./../assets/gallery/1339518.jpg";
import m1 from "./../assets/gallery/138261.jpg";
import m2 from "./../assets/gallery/138262.jpg";
import m3 from "./../assets/gallery/138263.jpg";
import m4 from "./../assets/gallery/138264.jpg";
import m5 from "./../assets/gallery/138265.jpg";
import m6 from "./../assets/gallery/138266.jpg";
import m7 from "./../assets/gallery/138267.jpg";
import m15 from "./../assets/gallery/1382615.jpg";
import m16 from "./../assets/gallery/1382616.jpg";
import m17 from "./../assets/gallery/1382617.jpg";
import m18 from "./../assets/gallery/1382618.jpg";
import m19 from "./../assets/gallery/1382619.jpg";
import m21 from "./../assets/gallery/1382621.jpg";
import n1 from "./../assets/gallery/385951.jpg";
import n4 from "./../assets/gallery/385954.jpg";
import n5 from "./../assets/gallery/385955.jpg";
import n8 from "./../assets/gallery/385958.jpg";
import n9 from "./../assets/gallery/385959.jpg";
import n10 from "./../assets/gallery/3859510.jpg";
import n11 from "./../assets/gallery/3859511.jpg";
import n13 from "./../assets/gallery/3859513.jpg";
import n15 from "./../assets/gallery/3859515.jpg";
import o2 from "./../assets/gallery/ps2.jpg";
import o5 from "./../assets/gallery/ps5.jpg";
import o6 from "./../assets/gallery/ps6.jpg";
import o8 from "./../assets/gallery/ps8.jpg";
import o9 from "./../assets/gallery/ps9.jpg";
import o12 from "./../assets/gallery/ps12.jpg";
import o15 from "./../assets/gallery/ps15.jpg";
import o28 from "./../assets/gallery/ps28.jpg";
import o34 from "./../assets/gallery/ps34.jpg";
import o59 from "./../assets/gallery/ps59.jpg";
import o60 from "./../assets/gallery/ps60.jpg";
import o64 from "./../assets/gallery/ps64.jpg";
import o65 from "./../assets/gallery/ps65.jpg";
import o66 from "./../assets/gallery/ps66.jpg";
import o71 from "./../assets/gallery/ps71.jpg";
import o72 from "./../assets/gallery/ps72.jpg";
import p1 from "./../assets/gallery/31411.jpg";
import p2 from "./../assets/gallery/31412.jpg";
import p4 from "./../assets/gallery/31414.jpg";
import p5 from "./../assets/gallery/31415.jpg";
import p6 from "./../assets/gallery/31416.jpg";
import p7 from "./../assets/gallery/31417.jpg";
import q1 from "./../assets/gallery/Park1.jpg";
import q2 from "./../assets/gallery/Park2.jpg";
import q5 from "./../assets/gallery/Park5.jpg";
import q6 from "./../assets/gallery/Park6.jpg";
import q7 from "./../assets/gallery/Park7.jpg";
import q8 from "./../assets/gallery/Park8.jpg";
import q10 from "./../assets/gallery/Park10.jpg";
import q11 from "./../assets/gallery/Park11.jpg";
import q13 from "./../assets/gallery/Park13.jpg";
import q15 from "./../assets/gallery/Park15.jpg";
import q16 from "./../assets/gallery/Park16.jpg";
import q18 from "./../assets/gallery/Park18.jpg";
import q19 from "./../assets/gallery/Park19.jpg";
import q20 from "./../assets/gallery/Park20.jpg";
import r1 from "./../assets/gallery/90101.jpg";
import r3 from "./../assets/gallery/90103.jpg";
import r4 from "./../assets/gallery/90104.jpg";
import r5 from "./../assets/gallery/90105.jpg";
import r8 from "./../assets/gallery/90108.jpg";
import r9 from "./../assets/gallery/90109.jpg";
import r10 from "./../assets/gallery/901010.jpg";
import r11 from "./../assets/gallery/901011.jpg";
import r13 from "./../assets/gallery/901013.jpg";
import r15 from "./../assets/gallery/901015.jpg";
import r17 from "./../assets/gallery/901017.jpg";
import r18 from "./../assets/gallery/901018.jpg";
import r19 from "./../assets/gallery/901019.jpg";
import r20 from "./../assets/gallery/901020.jpg";
import s1 from "./../assets/gallery/villa1.jpg";
import s2 from "./../assets/gallery/villa2.jpg";
import s3 from "./../assets/gallery/villa3.jpg";
import s4 from "./../assets/gallery/villa4.jpg";
import s6 from "./../assets/gallery/villa6.jpg";
import s7 from "./../assets/gallery/villa7.jpg";
import s8 from "./../assets/gallery/villa8.jpg";
import s9 from "./../assets/gallery/villa9.jpg";
import s10 from "./../assets/gallery/villa10.jpg";
import s11 from "./../assets/gallery/villa11.jpg";
import s12 from "./../assets/gallery/villa12.jpg";
import s13 from "./../assets/gallery/villa13.jpg";
import s14 from "./../assets/gallery/villa14.jpg";
import s15 from "./../assets/gallery/villa15.jpg";
import s16 from "./../assets/gallery/villa16.jpg";
import s17 from "./../assets/gallery/villa17.jpg";
import s18 from "./../assets/gallery/villa18.jpg";
import s19 from "./../assets/gallery/villa19.jpg";
import s20 from "./../assets/gallery/villa20.jpg";
import t1 from "./../assets/gallery/nic1.jpg";
import u1 from "./../assets/gallery/cow1.jpg";
import v1 from "./../assets/gallery/4501.jpg";
import v2 from "./../assets/gallery/4502.jpg";
import v3 from "./../assets/gallery/4503.jpg";
import v4 from "./../assets/gallery/4504.jpg";
import w1 from "./../assets/gallery/7461.jpg";
import w2 from "./../assets/gallery/7462.jpg";
import w3 from "./../assets/gallery/7463.jpg";
import w4 from "./../assets/gallery/7464.jpg";
import w5 from "./../assets/gallery/7465.jpg";
import w6 from "./../assets/gallery/7466.jpg";
import w7 from "./../assets/gallery/7467.jpg";
import w8 from "./../assets/gallery/7468.jpg";
import w9 from "./../assets/gallery/7469.jpg";
import x1 from "./../assets/gallery/19201.jpg";
import y1 from "./../assets/gallery/24651.jpg";
import y2 from "./../assets/gallery/24652.jpg";
import y3 from "./../assets/gallery/24653.jpg";
import y4 from "./../assets/gallery/24654.jpg";
import z1 from "./../assets/gallery/28621.jpg";
import z2 from "./../assets/gallery/28622.jpg";
import z3 from "./../assets/gallery/28623.jpg";
import z4 from "./../assets/gallery/28624.jpg";
import z5 from "./../assets/gallery/28625.jpg";
import z6 from "./../assets/gallery/28626.jpg";
import z7 from "./../assets/gallery/28627.jpg";
import swaan1 from "./../assets/gallery/swaan1.jpg";
import swaan2 from "./../assets/gallery/swaan2.jpg";
import swaan3 from "./../assets/gallery/swaan3.jpg";

// Data for projects
const madronaImages = [
  { url: madrona1 },
  { url: madrona2 },
  { url: madrona3 },
  { url: madrona4 },
  { url: madrona5 },
  { url: madrona6 },
  { url: madrona7 },
  { url: madrona8 },
];

const morganImages = [
  { url: morgan1 },
  { url: morgan2 },
  { url: morgan3 },
  { url: morgan6 },
  { url: morgan7 },
  { url: morgan9 },
  { url: morgan14 },
  { url: morgan15 },
];

const narrowsImages = [
  { url: narrows1 },
  { url: narrows2 },
  { url: narrows3 },
  { url: narrows4 },
  { url: narrows5 },
  { url: narrows6 },
];

const lionsbayImages = [
  { url: lionsbayext1 },
  { url: lionsbayext3 },
  
  { url: lionsbay1 },
  { url: lionsbay2 },
  { url: lionsbay3 },
  { url: lionsbay4 },
  { url: lionsbay5 },
  { url: lionsbay6 },
  { url: lionsbay7 },
  { url: lionsbay8 },
];

const craftImages = [
  { url: craft1 },
  { url: craft2 },
  { url: craft3 },
  { url: craft4 },
  { url: craft5 },
  { url: craft6 },
  { url: craft7 },
  { url: craft9 },
  { url: craft10 },
  { url: craft11 },
];

const eHastingsImages = [
  { url: eHastings10 },
  { url: eHastings1 },
  { url: eHastings3 },
  { url: eHastings4 },
  { url: eHastings5 },
  { url: eHastings6 },
  { url: eHastings7 },
  { url: eHastings8 },
  { url: eHastings9 },
  { url: eHastings2 },
];

const dImages = [
  { url: d15 },
  { url: d2 },
  { url: d4 },
  { url: d5 },
  { url: d7 },
  { url: d10 },
  { url: d11 },
  { url: d12 },
  { url: d13 },
  { url: d14 },
];

const eImages = [
  { url: e17 },
  { url: e2 },
  { url: e3 },
  { url: e4 },
  { url: e5 },
  { url: e7 },
  { url: e8 },
  { url: e9 },
  { url: e10 },
  { url: e11 },
  { url: e12 },
  { url: e13 },
  { url: e15 },
  { url: e16 },
  { url: e18 },
];

const fImages = [
  { url: f17 },
  { url: f2 },
  { url: f7 },
  { url: f9 },
  { url: f11 },
  { url: f14 },
  { url: f21 },
];

const gImages = [
  { url: g2 },
  { url: g3 },
  { url: g5 },
];

const hImages = [
  { url: hr },
  { url: h9 },
  { url: h10 },
  { url: h2 },
  { url: h1 },
  { url: h8 },
  { url: h4 },
  { url: h3 },
  { url: h5 },
  { url: h6 },
  { url: h7 },

];

const iImages = [
  { url: i1 },
  { url: i12 },
  { url: i13 },
  { url: i14 },
  { url: i11 },
  { url: i15 },
  { url: i16 },
  { url: i17 },
  { url: i18 },
  { url: i2 },
  { url: i3 },
  { url: i4 },
  { url: i5 },
  { url: i6 },
  { url: i7 },
  { url: i8 },
  { url: i9 },
  { url: i10 },

];

const jImages = [
  { url: j4 },
  { url: j5 },
  { url: j6 },
  { url: j7 },
  { url: j8 },
  { url: j9 },
  { url: j10 },
  { url: j11 },
  { url: j1 },
  { url: j2 },
];

const kImages = [
  { url: kr },
  { url: k13 },
  { url: k15 },
  { url: k23 },
  { url: k16 },
  { url: k18 },
  { url: k19 },
  { url: k3 },
  { url: k4 },
  { url: k1 },
  { url: k2 },
  { url: k5 },
  { url: k12 },
  { url: k7 },
  { url: k8 },
  { url: k9 },
  { url: k10 },
  { url: k11 },
  { url: k14 },
];

const lImages = [
  { url: l7 },
  { url: l4 },
  { url: l5 },
  { url: l11 },
  { url: l13 },
  { url: l18 },
];

const mImages = [
  { url: m18 },
  { url: m15 },
  { url: m16 },
  { url: m17 },
  { url: m19 },
  { url: m21 },
  { url: m1 },
  { url: m2 },
  { url: m3 },
  { url: m4 },
  { url: m5 },
  { url: m6 },
  { url: m7 },
 
];

const nImages = [
  { url: n1 },
  { url: n4 },
  { url: n5 },
  { url: n8 },
  { url: n9 },
  { url: n10 },
  { url: n11 },
  { url: n13 },
  { url: n15 },

];

const oImages = [
  { url: o2 },
  { url: o5 },
  { url: o6 },
  { url: o8 },
  { url: o9 },
  { url: o12 },
  { url: o15 },
  { url: o28 },
  { url: o34 },
  { url: o59 },
  { url: o60 },
  { url: o64 },
  { url: o65 },
  { url: o66 },
  { url: o71 },
  { url: o72 },

];

const pImages = [
  { url: p1 },
  { url: p2 },
  { url: p4 },
  { url: p5 },
  { url: p6 },
  { url: p7 },
];

const qImages = [
  { url: q1 },
  { url: q2 },
  { url: q5 },
  { url: q6 },
  { url: q7 },
  { url: q8 },
  { url: q10 },
  { url: q11 },
  { url: q13 },
  { url: q15 },
  { url: q16 },
  { url: q18 },
  { url: q19 },
  { url: q20 },
];

const rImages = [
  { url: r1 },
  { url: r9 },
  { url: r8 },
  { url: r10 },
  { url: r11 },
  { url: r13 },
  { url: r3 },
  { url: r4 },
  { url: r5 },
  { url: r15 },
  { url: r17 },
  { url: r18 },
  { url: r19 },
  { url: r20 },

];

const sImages = [
  { url: s2 },
  { url: s1 },
  { url: s3 },
  { url: s4 },
  // { url: s5 },
  { url: s6 },
  { url: s7 },
  { url: s8 },
  { url: s9 },
  { url: s10 },
  { url: s11 },
  { url: s12 },
  { url: s13 },
  { url: s14 },
  { url: s15 },
  { url: s16 },
  { url: s17 },
  { url: s18 },
  { url: s19 },
  { url: s20 },
];

const nicImages = [
  { url: t1 }, 
];

const cowImages = [
  { url: u1 },
];

const vImages = [
  { url: v1 },
  { url: v2 },
  { url: v3 },
  { url: v4 },
];

const wImages = [
  { url: w1 },
  { url: w2 },
  { url: w3 },
  { url: w4 },
  { url: w5 },
  { url: w6 },
  { url: w7 },
  { url: w8 },
  { url: w9 },
];

const xImages = [
  { url: x1 }, 
];

const yImages = [
  { url: y1 },
  { url: y2 },
  { url: y3 },
  { url: y4 },
];

const zImages = [
  { url: z1 },
  { url: z2 },
  { url: z3 },
  { url: z4 },
  { url: z5 },
  { url: z6 },
  { url: z7 },
];

const swaanImages = [ 
  { url: swaan1 },
  { url: swaan2 },
  { url: swaan3 },
];

const Project = ({ projectName, mainImage, images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = () => {
    setSelectedImage(images[0].url);
    setCurrentIndex(0);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const openImage = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  };

  const nextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[newIndex].url);
    setCurrentIndex(newIndex);
  };

  const prevImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex].url);
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative my-8">
      <div className="w-full bg-white cursor-pointer" onClick={openModal}>
        <img
          src={mainImage}
          alt={projectName}
          className="object-cover w-full h-full"
          style={{ aspectRatio: "5/3", width: "100%", height: "auto" }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
          <h2 className="text-white text-2xl font-bold">{projectName}</h2>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Images"
        className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        <div className="bg-white p-4 rounded-lg max-w-7xl w-full relative">
          <button
            className="text-black font-bold mb-4"
            onClick={closeModal}
          >
            Close
          </button>
          {selectedImage ? (
            <div className="flex justify-center items-center relative">
              <button
                className="absolute left-0 text-white text-4xl font-bold p-2"
                onClick={prevImage}
              >
                &#10094;
              </button>
              <img
                src={selectedImage}
                alt="Selected"
                className="object-cover w-full h-full"
                style={{ maxHeight: "112vh", maxWidth: "112vw" }}
                
              />
              <button
                className="absolute right-0 text-white text-4xl font-bold p-2"
                onClick={nextImage}
              >
                &#10095;
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {images.map((image, index) => (
                <div key={index} className="w-full bg-white cursor-pointer" onClick={() => openImage(image.url, index)}>
                  <img
                    src={image.url}
                    alt={image.title}
                    className="object-cover w-full h-full"
                    style={{ aspectRatio: "5/3", width: "100%", height: "auto" }}
                  />
                  <p className="text-center font-medium mt-2">{image.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const Gallery = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        PROJECTS
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 p-6">
        {/* Lions Bay */}
        <Project projectName="Lions Bay" mainImage={lionsbayext1} images={lionsbayImages} />

        {/* Craft Residence */}
        <Project projectName="Craft Residence" mainImage={craft1} images={craftImages} />

        {/* Madrona House */}
        <Project projectName="Madrona Drive" mainImage={madrona1} images={madronaImages} />

        {/* Project Q */}
        <Project projectName="Park Residence" mainImage={q2} images={qImages} />

        {/* Project R */}
        <Project projectName="Royal St" mainImage={r1} images={rImages} />

        {/* Project X */}
        <Project projectName="Anmore" mainImage={x1} images={xImages} />

        {/* Project S */}
        <Project projectName="Villa Project" mainImage={s2} images={sImages} />

        {/* The Narrows */}
        <Project projectName="The Narrows" mainImage={narrows1} images={narrowsImages} />

        {/* Project I */}
        <Project projectName="Pandora Drive" mainImage={i1} images={iImages} />

        {/* 2601 East Hastings */}
        <Project projectName="2601 East Hastings" mainImage={eHastings10} images={eHastingsImages} />

        {/* Project E */}
        <Project projectName="E 18th" mainImage={e17} images={eImages} />

        {/* Project F */}
        <Project projectName="Crescent Residence" mainImage={f17} images={fImages} />

        {/* Project Z */}
        <Project projectName="Fort Langley" mainImage={z1} images={zImages} />
       

        {/* Project K */}
        <Project projectName="15A Ave" mainImage={kr} images={kImages} />

        {/* Project W */}
        <Project projectName="Regal" mainImage={w1} images={wImages} />

        {/* Project Y */}
        <Project projectName="Revelstoke" mainImage={y1} images={yImages} />

        {/* Project G */}
        <Project projectName="Pivot Project" mainImage={g2} images={gImages} />

        {/* Project Swaan */}
        <Project projectName="Swaan" mainImage={swaan1} images={swaanImages} />

        {/* Project V */}
        <Project projectName="Macbeth" mainImage={v1} images={vImages} />

        {/* Project H */}
        <Project projectName="236A St" mainImage={hr} images={hImages} />

        {/* Project D */}
        <Project projectName="E 15th" mainImage={d15} images={dImages} />

        {/* Project J */}
        <Project projectName="27 Ave" mainImage={j4} images={jImages} />

        {/* Project P */}
        <Project projectName="Serano" mainImage={p1} images={pImages} />

        {/* Project L */}
        <Project projectName="28 Ave" mainImage={l7} images={lImages} />

        {/* Project M */}
        <Project projectName="41 Ave" mainImage={m18} images={mImages} />

        {/* Project N */}
        <Project projectName="Highcreek" mainImage={n1} images={nImages} />

        {/* Project O */}
        <Project projectName="Plan Your Space" mainImage={o2} images={oImages} />

         {/* Morgan Crossing */}
         <Project projectName="Morgan Creek" mainImage={morgan1} images={morganImages} />

        {/* Project NIC */}
        <Project projectName="North Island College" mainImage={t1} images={nicImages} />

        {/* Project Cow */}
        <Project projectName="Cowichan Secondary" mainImage={u1} images={cowImages} />
      </div>
    </div>
  );
};

export default Gallery;