import React from "react";

import w77a from "../../../assets/w77/w77a.jpg";
import w77b from "../../../assets/w77/w77b.jpg";
import w77c from "../../../assets/w77/w77c.jpg";
import w77d from "../../../assets/w77/w77d.jpg";
import w771 from "../../../assets/w77/w771.webp";
import w772 from "../../../assets/w77/w772.webp";
import w773 from "../../../assets/w77/w773.webp";
import w774 from "../../../assets/w77/w774.webp";
import w775 from "../../../assets/w77/w775.webp";
import w776 from "../../../assets/w77/w776.webp";
import w777 from "../../../assets/w77/w777.webp";
import w778 from "../../../assets/w77/w778.webp";
import w779 from "../../../assets/w77/w779.webp";
import w77Hero from "../../../assets/w77/W77section2.jpg";

import w77sec1 from "../../../assets/w77/w77sec1.jpg";
import w77sec2 from "../../../assets/w77/w77sec2.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const W77 = () => {
  return (
    <div>
      <div className="flex flex-col justify-center pt-12 pb-12 md:pt-32 bg-white">
        <div className="md:container relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto">
          <div className="w-full md:w-2/4 pt-2 md:p-6 flex flex-col space-y-2 md:pb-10">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6">
              ELVIAL W77
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              ULTRA SLIM SERIES
            </h3>

            <div className="md:hidden max-w-xl pb-6">
              <img
                src={w77Hero}
                alt="W77 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="md:p-12 md:pr-32 md:pl-32">
              <p className="text-left leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                An ingenuous take on elegance. The precise lines, slim contours and attenuated particulars that make the Iconic collection aesthetically exceptional are present in this solution. Put your creativity to work to see what it can do for you.
              </p>
              <p className="text-left leading-8 font-montserrat font-semibold text-xl tracking-narrow md:pb-2">
                Specifications
              </p>
              <div className="md:block md:pb-12">
                <div className="grid md:grid-cols-3 max-w-7xl md:text-base text-left pt-6">
                  <span className="pr-10 font-semibold">
                    SYSTEM DEPTH
                    <span className="pr-10 block font-normal pb-4">77 mm</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MIN FACE HEIGHT FRAME
                    <span className="pr-10 block font-normal pb-4">89 mm</span>
                  </span>

                  {/* <span className="pr-10 font-semibold">
                    MIN FACE HEIGHT THRESHOLD
                    <span className="pr-10 block font-normal pb-4">79 mm</span>
                  </span> */}

                  {/* <span className="pr-10 font-semibold">
                    MIN FACE WIDTH
                    <span className="pr-10 block font-normal pb-4">154 mm</span>
                  </span> */}

                  <span className="pr-10 font-semibold">
                    MAX SASH HEIGHT
                    <span className="pr-10 block font-normal pb-4">2.800 mm</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MAX SASH WIDTH
                    <span className="pr-10 block font-normal pb-4">1.600 mm</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MAX SASH WEIGHT
                    <span className="pr-10 block font-normal pb-4">200 kg</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MAXIMUM GLASS THICKNESS
                    <span className="pr-10 block font-normal pb-4">70 mm</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-2/4 pr-32 h-full">
            <img
              src={w77Hero}
              alt="W77 Cover"
              className="object-fit w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center pb-12 bg-white">
        <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12">
          <div className="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
            <div className="w-full">
              <img src={w77a} alt="W77 Render 1" />
            </div>
            <div className="w-full">
              <img src={w77b} alt="W77 Render 2" />
            </div>
            <div className="w-full">
              <img src={w77c} alt="W77 Render 2" />
            </div>
            <div className="w-full">
              <img src={w77d} alt="W77 Render 2" />
            </div>
          </div>
        </div>

        <div className="md:container relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto">
          <div className="w-full md:w-4/4 md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6 ">
                          <span className="pr-10 pb-4 ">
                            <img
                              src={w771}
                              alt="W77 Render"
                              className="object-contain h-24 mt-auto mx-auto"
                            />
                            Odd number of vents on one side
                          </span>
                          <span className="pr-10 pb-4  ">
                            {" "}
                            <img
                              src={w772}
                              alt="W77 Render"
                              className="object-contain h-24  mx-auto"
                            />
                            Odd number of vents plus door
                          </span>
                          <span className="pr-10 pb-4  ">
                            <img
                              src={w773}
                              alt="W77 Render "
                              className="object-contain h-24  mx-auto"
                            />
                            Odd number of vents on both sides (meeting stile)
                          </span>
                          <span className="pr-10 pb-4">
                            {" "}
                            <img
                              src={w774}
                              alt="W77 Render"
                              className="object-contain h-24  mx-auto"
                            />
                            Odd number of vents on one side
                          </span>
                          <span className="pr-10 pb-4">
                            <img
                              src={w775}
                              alt="W77 Render"
                              className="object-contain h-24  mx-auto"
                            />
                            Corner construction with single door
                          </span>
                          <span className="pr-10 pb-4">
                            <img
                              src={w776}
                              alt="W77 Render"
                              className="object-contain h-24  mx-auto"
                            />
                            Corner construction with odd number of vents on both sides
                          </span>
            
                          <span className="pr-10 pb-4 ">
                            <img
                              src={w777}
                              alt="W77 Render"
                              className="object-contain h-24 mx-auto "
                            />
                            Odd number of vents on both sides (meeting stile)
                          </span>
                          <span className="pr-10 pb-4  ">
                            <img
                              src={w778}
                              alt="W77 Render"
                              className="object-contain h-24  mx-auto"
                            />
                            Even number of vents on one side
                          </span>
                          <span className="pr-10 pb-4  ">
                            {" "}
                            <img
                              src={w779}
                              alt="W77 Render "
                              className="object-contain h-24  mx-auto"
                            />
                            Even number of vents plus door
                          </span>
                        </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-left pb-2">
        <div className="md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white">
          <div className="md:w-full md:2/2 grid place-items-center">
            <img
              src={w77sec1}
              alt="W77 Section 1"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="md:w-full md:2/2 grid place-items-center">
            <img
              src={w77sec2}
              alt="W77 Section 2"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      

      <div className="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center">
        <div>
          <a
            href="https://www.elvial.gr/sites/default/files/2024-07/ICONIC%20W77ST%20%CE%91I2%20EN.pdf"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            W77 System Specs
          </a>
        </div>
      </div>

    
    </div>
  );
};

export default W77;