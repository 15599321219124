import React from "react";

import pivotCover from "../../../assets/entrance/PivotCover.webp";


// Import all pivot door images
import pivot3 from "../../../assets/entrance/pivot3.png";
import pivot5 from "../../../assets/entrance/pivot5.png";
import pivot9 from "../../../assets/entrance/pivot9.png";
import pivot10 from "../../../assets/entrance/pivot10.png";
import pivot11 from "../../../assets/entrance/pivot11.png";
import pivot12 from "../../../assets/entrance/pivot12.png";
import pivot13 from "../../../assets/entrance/pivot13.png";
import pivot14 from "../../../assets/entrance/pivot14.png";
import pivot15 from "../../../assets/entrance/pivot15.png";
import pivot16 from "../../../assets/entrance/pivot16.png";
import pivot17 from "../../../assets/entrance/pivot17.png";
import pivot18 from "../../../assets/entrance/pivot18.png";
import pivot19 from "../../../assets/entrance/pivot19.png";
import pivot20 from "../../../assets/entrance/pivot20.png";
import pivot21 from "../../../assets/entrance/pivot21.png";
import pivot22 from "../../../assets/entrance/pivot22.png";
import pivot24 from "../../../assets/entrance/pivot24.png";
import pivot25 from "../../../assets/entrance/pivot25.png";
import pivot26 from "../../../assets/entrance/pivot26.png";
import pivot27 from "../../../assets/entrance/pivot27.png";
import pivot28 from "../../../assets/entrance/pivot28.png";
import pivot29 from "../../../assets/entrance/pivot29.png";
import pivot30 from "../../../assets/entrance/pivot30.png";
import pivot31 from "../../../assets/entrance/pivot31.png";
import pivot32 from "../../../assets/entrance/pivot32.png";
import pivot33 from "../../../assets/entrance/pivot33.png";
import pivot34 from "../../../assets/entrance/pivot34.png";

// Array of pivot door images
const pivotImages = [
  pivot3, pivot5, pivot9, pivot10,
  pivot11, pivot12, pivot13, pivot14, pivot15, pivot16, pivot17, pivot18, pivot19, pivot20,
  pivot21, pivot22, pivot24, pivot25, pivot26, pivot27, pivot28, pivot29, pivot30,
  pivot31, pivot32, pivot33, pivot34
];

const Entrance = () => {
  return (
    <div>
      <h1 className="door-cover text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wider p-12 md:p-32">
        PIVOT DOORS
      </h1>

      <div className="flex flex-col justify-center pb-12 md:pt-12 md:pb-24 bg-white">
        <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto">
          <div className="w-full md:w-2/4 grid place-items-center">
            <img
              src={pivotCover}
              alt="Doors cover"
              className="object-cover h-full w-full"
            />
          </div>
          <div className="bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR PIVOT DOORS
            </h3>
            <p className="text-left leading-8 md:text-base font-montserrat font-medium tracking-narrow">
              Varsa Windows and Doors provide a stunning solution for modern entryways with our pivot doors. These doors feature a central hinge that allows for smooth and effortless opening, creating a dramatic entrance.
            </p>
            <p className="text-left leading-8 md:text-base font-montserrat font-medium tracking-narrow">
              Pivot doors are available in various sizes and finishes with biometric unlock, led lighting, custom design, and more offering flexibility in design to match your home's aesthetic. With options for double or triple glazing, they provide excellent thermal and sound insulation. Enhanced security features include multi-point locking mechanisms, ensuring safety without compromising style.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center pt-2 pb-2">
      

        <div className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {pivotImages.map((image, index) => (
              <div key={index} className="w-full bg-white grid place-items-left transform transition duration-500 hover:scale-105">
                <img
                  src={image}
                  alt={`Pivot Door ${index + 1}`}
                  className="object-cover w-full h-full"
                />
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entrance;