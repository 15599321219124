import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import elvialCover from "../../../assets/elvial/elvialCover.webp";
import w77 from "../../../assets/elvial/w77.webp";


const Elvial = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-7xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ELVIAL
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              ULTRA SLIM SERIES
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={elvialCover}
                alt="Elvial Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
              What is a space all about? Atmosphere, people that inhabit and use it, the feeling of belonging? Is it about boundaries? Separating what is inside from what is outside? Space is all about you. What you consider important. We create the frame that shapes your vision, keeps it safe and makes it beautiful.
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={elvialCover}
              alt="Elvial Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        SERIES
      </h2>

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-7xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="w77">
              <img
                src={w77}
                alt="w77 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
             W77 AI2
            </span>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Elvial;
