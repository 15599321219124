import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useForm, ValidationError } from '@formspree/react';
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [state, handleSubmit] = useForm("mnqkzvbd");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleFormSubmit = (event) => {
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      event.preventDefault();
      return;
    }
    handleSubmit(event);
  };

  if (state.succeeded) {
    return <p className="formSuccess"> Thanks for reaching out to us! We will contact you shortly. </p>;
  }

  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        CONTACT US
      </h1>
      <div className="flex flex-col justify-center md:pt-12 pb-32 bg-white">
        <div className="bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-6 md:p-14 max-w-sm md:max-w-6xl mx-auto">
          <div className="w-full md:w-2/4 grid place-items-left">
            <p className="text-left leading-8 font-montserrat font-medium tracking-narrow pb-5">
              Your thoughts and inquiries matter to us! We're here to assist you. Please don't hesitate to reach out by filling in the email form below. Whether you have questions, feedback, or just want to say hello, we're eager to hear from you. Thank You
            </p>
            <p className="text-left leading-8 font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon className="pr-2" icon={faPhone} style={{ color: "#E5B50B" }} /> 604 213 5232
            </p>
            <p className="text-left leading-8 font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon className="pr-2" icon={faEnvelope} style={{ color: "#E5B50B" }} /> Info@varsa.ca
            </p>
            <p className="flex text-left leading-8 font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon className="pl-1 pr-2 py-1" icon={faLocationDot} style={{ color: "#E5B50B" }} /> 105 - 15292 Croydon Drive, Surrey, British Columbia V3Z 0Z5, Canada
            </p>
          </div>
          <div className="bg-[#F1F1F1] w-full md:w-3/4 text-left flex flex-col space-y-2">
            <form onSubmit={handleFormSubmit}>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label htmlFor="first_name" className="block mb-2 font-montserrat font-medium">
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    placeholder="First name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="last_name" className="block mb-2 font-montserrat font-medium">
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    placeholder="Last name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 font-montserrat font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="_replyto"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    placeholder="name@mail.com"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block mb-2 font-montserrat font-medium">
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    placeholder="123-456-7891"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block mb-2 font-montserrat font-medium">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    placeholder="Enter your message"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="how_did_you_hear" className="block mb-2 font-montserrat font-medium">
                    How did you hear about us
                  </label>
                  <select
                    id="how_did_you_hear"
                    name="how_did_you_hear"
                    className="bg-gray-50 border border-[#E5B50B] text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Referral">Referral</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Google">Google</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <ReCAPTCHA
                sitekey="6LcDhtsqAAAAAJf84M2VN46zCpbwUdy_AonhU0lW" // Replace with your actual site key
                onChange={onRecaptchaChange}
              />
              <button
                type="submit"
                disabled={state.submitting}
                className="bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500"
              >
                SEND MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;