import React from "react";
import s67Cover from "../../../assets/s67/s67Cover.jpg";
import s67a from "../../../assets/s67/s67a.jpg";
import s67b from "../../../assets/s67/s67b.jpg";
import s671 from "../../../assets/s67/s671.webp";
import s672 from "../../../assets/s67/s672.webp";
import s673 from "../../../assets/s67/s673.webp";
import s674 from "../../../assets/s67/s674.webp";
import s675 from "../../../assets/s67/s675.webp";
import s676 from "../../../assets/s67/s676.webp";
import s677 from "../../../assets/s67/s677.webp";
import s678 from "../../../assets/s67/s678.webp";
import s67Hero from "../../../assets/alumil/S67Section.jpg";

import s67sec1 from "../../../assets/s67/s67sec1.png";
import s67sec2 from "../../../assets/s67/s67sec2.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S67 = () => {
  return (
    <div>
      <div className="flex flex-col justify-center pt-12 pb-12 md:pt-32 bg-white">
        <div className="md:container relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto">
          <div className="w-full md:w-2/4 pt-2 md:p-6 flex flex-col space-y-2 md:pb-10">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6">
              ALUMIL S67
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
              Hinged insulated system SMARTIA S67
            </h3>

            <div className="md:hidden max-w-xl pb-6">
              <img
                src={s67Hero}
                alt="S67 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="md:p-12 md:pr-32 md:pl-32">
              <p className="text-left leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
                The SMARTIA S67 is the new complete Tilt & Turn system of ALUMIL, which combines outstanding thermal insulation performances along with high construction flexibility and exceptional design.
              </p>
              <p className="text-left leading-8 font-montserrat font-semibold text-xl tracking-narrow md:pb-2">
                Specifications
              </p>
              <div className="md:block md:pb-12">
                <div className="grid md:grid-cols-3 max-w-7xl md:text-base text-left pt-6">
                  <span className="pr-10 font-semibold">
                    WINDOW TYPE
                    <span className="pr-10 block font-normal pb-4">Hinged</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    FRAME DEPTH
                    <span className="pr-10 block font-normal pb-4">67 mm</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    U-VALUE
                    <span className="pr-10 block font-normal pb-4">
                      from 1.1 to 2.6 W/m²K
                    </span>
                  </span>

                  <span className="pr-10 font-semibold">
                    BURGLAR RESISTANCE
                    <span className="pr-10 block font-normal pb-4">RC2/RC3</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    WATER TIGHTNESS
                    <span className="pr-10 block font-normal pb-4">
                      CLASS E1350
                    </span>
                  </span>
                  <span className="pr-10 font-semibold">
                    AIR PERMEABILITY
                    <span className="pr-10 block font-normal pb-4">CLASS 4</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    BURGLAR RESISTANCE
                    <span className="pr-10 block font-normal pb-4">RC2/RC3</span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MAXIMUM GLAZING THICKNESS
                    <span className="pr-10 block font-normal pb-4">
                      up to 58 mm
                    </span>
                  </span>

                  <span className="pr-10 font-semibold">
                    MINIMUM VISIBLE ALUMINIUM FACE HEIGHT
                    <span className="pr-10 block font-normal pb-4">93 mm</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-2/4 pr-32 h-full">
            <img
              src={s67Hero}
              alt="S67 Cover"
              className="object-fit w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center pb-12 bg-white">
        <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12">
          <div className="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
            <div className="w-full">
              <img src={s67a} alt="S67 Render 1" />
            </div>
            <div className="w-full">
              <img src={s67Cover} alt="S67 Render 1" />
            </div>
          </div>
        </div>

        <div className="md:container relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto">
          <div className="w-full md:w-4/4 md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-montserrat text-left pt-6">
              <span className="pr-10 pb-4">
                <img
                  src={s671}
                  alt="S67 Sectional"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Fixed window
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s672}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Double fixed lights
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s673}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                One sash tilt and turn
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s674}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Tilt and turn with fix light on top
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s675}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Tilt and turn with fanlight
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s676}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Double tilt and turn window
              </span>

              <span className="pr-10 pb-4">
                <img
                  src={s677}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Double tilt and turn window with fixed light
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={s678}
                  alt="S67 Sectional"
                  className="object-contain h-24 mx-auto"
                />
                Double tilt and turn window with fanlight
              </span>
            </div>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-left pb-2">
        <div className="md:max-w-7xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 bg-white">
          <div className="md:w-full md:2/2 grid place-items-center">
            <img
              src={s67sec1}
              alt="S67 Sectional"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="md:w-full md:2/2 grid place-items-center">
            <img
              src={s67sec2}
              alt="S67 Sectional"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div className="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 items-center">
        <div>
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/smartia-brochure-gr-en.pdf?sfvrsn=2dc3db89_78"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            Smartia Aluminum Systems
          </a>
        </div>
      </div>

      <div className="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center">
        <div>
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/smartia/pre-qualification-s(m)67.pdf?sfvrsn=7a0a9d1d_9"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            S67 System Specs
          </a>
        </div>
      </div>

      <div className="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center">
        <div>
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/smartia/s67-technical-brochures.pdf?sfvrsn=a85f933c_71"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "red" }}
            />{" "}
            S67 Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default S67;