import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import windowCover from "../../assets/windows/window1.webp";
import sd95 from "../.././assets/sd95/sd95Passive.webp";
import s91 from "../.././assets/alumil/s91Passive.webp";
import m7 from "../../assets/alumilm7Passive.webp";
import rehau from "../../assets/windows/window5Passive.webp";

const Passive = () => {
  return (
    <div>
      {" "}
      <h1 className="door-cover text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        PASSIVE HOUSING
      </h1>
      <div className=" flex flex-col justify-center pb-12 md:pt-12  bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={windowCover}
              alt="Passive Cover"
              className="object-cover w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              Our Products
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              At Varsa Windows and Doors, we are proud to present our passive
              certified collection. Discover a range of revolutionary products
              designed to take your living spaces to the forefront of energy
              efficiency and sustainability. Embrace the future of building with
              our exceptional lineup, engineered to exceed U values and surpass
              industry standards. Our cutting-edge solutions not only help you
              go passive but also elevate your comfort and reduce environmental
              impact. Join us on this eco-conscious journey as we transform the
              way we build and live. Step into a world of innovation and embrace
              the power of passive living with our outstanding products.
            </p>
          </div>
        </div>
      </div>
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        DOORS
      </h2>
      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="sd95">
              <img
                src={sd95}
                alt="Alumil sd95 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SUPREME SD95
            </span>
          </div>
        </div>
      </div>
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        WINDOWS
      </h2>
      <div className="  flex flex-col justify-center pt-2 pb-2 md:pb-12">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
            <Link to="s91">
              <img
                src={s91}
                alt="s91 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              S91
            </span>
          </div>
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="rehau">
              <img
                src={rehau}
                alt="Doors cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              REHAU GENEO (UPVC) SERIES{" "}
            </span>
          </div>
        </div>
      </div>
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        CURTAIN WALLS
      </h2>
      <div className="  flex flex-col justify-center pt-2 pb-2 md:pb-12">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="m7">
              <img
                src={m7}
                alt="Alumil m7 cover"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ALUMIL SMARTIA M7
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passive;
